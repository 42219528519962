import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import SEO from '../../components/atoms/seo'
import LpBottom01 from '../../components/organisms/lpBottom01'
import LpCta01 from '../../components/organisms/lpCta01'
import LpDownload01 from '../../components/organisms/lpDownload01'
import LpFeature01 from '../../components/organisms/lpFeature01'
import LpFooter01 from '../../components/organisms/lpFooter01'
import LpFunction01 from '../../components/organisms/lpFunction01'
import LpFv01A from '../../components/organisms/lpFv01A'
import LpHeader01 from '../../components/organisms/lpHeader01'
import LpMerit01 from '../../components/organisms/lpMerit01'
import LpPlan01 from '../../components/organisms/lpPlan01'
import LpSecurity01 from '../../components/organisms/lpSecurity01'
import LpSupport01 from '../../components/organisms/lpSupport01'
import LpWhatis01 from '../../components/organisms/lpWhatis01'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  .arr-down {
    display: flex;
    position: relative;
    justify-content: center;

    img {
      position: absolute;
      top: -40px;
      z-index: 1;
    }
  }

  ${BreakPoints.medium} {
    .arr-down {
      img {
        top: -20px;
        width: 12px;
      }
    }
  }
`

/**
 * キンクラLP-01-A
 * @constructor
 */
const Lp01AIndexPage = () => (
  <Wrapper>
    {/** ファーストビューA */}
    <SEO
      description="客先常駐特化型のクラウド勤怠管理システムならキンクラ。多種多様なシフトもテレワークも、キンクラなら従業員の勤務状況を一元管理することができます。
"
    />
    <Helmet>
      <title>
        勤務状況を一元管理できるキンクラ -
        客先常駐特化型のクラウド勤怠管理システム
      </title>
    </Helmet>
    <LpHeader01 signUpLink="https://sign-up.kintaicloud.jp/sign-up/?utm_source=lp&utm_medium=button&tran_source=lpa" />
    <LpFv01A />
    <LpCta01 signUpLink="https://sign-up.kintaicloud.jp/sign-up/?utm_source=lp&utm_medium=button&tran_source=lpa" />
    <div className="arr-down">
      <img
        src="/images/lp/lp01/lpArr01.png"
        alt="下にスクロールしてキンクラの詳細をご覧ください"
      />
    </div>
    <LpWhatis01 />
    <LpFeature01 />
    <LpMerit01 />
    <LpCta01 signUpLink="https://sign-up.kintaicloud.jp/sign-up/?utm_source=lp&utm_medium=button&tran_source=lpa" />
    <LpFunction01 />
    <LpSupport01 />
    <LpSecurity01 />
    <LpPlan01 />
    <LpBottom01 />
    <LpCta01 signUpLink="https://sign-up.kintaicloud.jp/sign-up/?utm_source=lp&utm_medium=button&tran_source=lpa" />
    <LpDownload01 />
    <LpFooter01 />
  </Wrapper>
)

export default Lp01AIndexPage

export const pageQuery = graphql`
  query Lp01AIndexPages {
    site {
      siteMetadata {
        title
      }
    }
  }
`
