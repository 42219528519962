import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  background-size: 100%;
  background: #ffffff url(/images/lp/lp01/lpBg01.png) no-repeat center -80px;

  .inner {
    width: 1380px;
    padding: 30px 0 20px 100px;
    position: relative;

    img {
      display: block;
      width: 100%;

      &.img-sp {
        display: none;
      }

      &.img-jiima {
        position: absolute;
        width: 200px;
        right: 50px;
        bottom: 50px;
      }
    }
  }

  ${BreakPoints.xxLarge} {
    .inner {
      width: 100vw;
      padding: 10px 0 20px 40px;
      overflow: hidden;

      img {
        width: 120%;

        &.img-jiima {
          width: 130px;
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .inner {
      padding: 20px 0 0 20px;

      img {
        width: 110%;

        &.img-pc {
          display: none;
        }

        &.img-sp {
          display: block;
        }

        &.img-jiima {
          width: 100px;
          right: -15px;
          bottom: unset;
          top: 100px;
        }
      }
    }
  }

  ${BreakPoints.small} {
  }
`

/**
 * LP01用ファーストビューAパターン
 * @constructor
 */
const LpFv01A = (): JSX.Element => (
  <Wrapper>
    <div className="inner">
      <img
        src="/images/lp/lp01/lpFvAPc.png"
        alt="多彩なシフトもテレワークも[クラウド勤怠管理システムのキンクラ]"
        className="img-pc"
      />
      <img
        src="/images/lp/lp01/lpFvASp.png"
        alt="多彩なシフトもテレワークも[クラウド勤怠管理システムのキンクラ]"
        className="img-sp"
      />
      <img src="/images/logo_jiima.png" className="img-jiima" alt="JIIMA認証" />
    </div>
  </Wrapper>
)

export default LpFv01A
